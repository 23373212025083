//Colors

$pinky:#FFCBCC;
$grey:#CBCBCB;
$white:white;
$black:#0B0B0B;
$accent:#9283FE;
$accent2:#59E8C0;
$accent3:#E249C6;

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    color: $white;
}

body,html{
    overflow-x: hidden;
    height: 100%;
}

section{
    width: 100vw;
    height: 100vh;
}

a{
    color:$white;
    transition: 0.3s ease;
    &:hover{
        color: $accent;
    }
}

svg{
    fill:white;
}

button{
    cursor: pointer;
}

.grey{
    color:$grey;
}

.px36{
    font-size: 36px;
}

.px18{
    font-size: 18px;
}

.px24{
    font-size: 24px;
}

.semi-bold{
    font-weight: 600;
}

.medium{
    font-weight: 500;
}

.hero__section{
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.personal-info__section{
    background: $black;
    position: relative;
    color: white;
    .background-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
    main{
        padding: 60px 60px 0px 60px;
        width: 100%;
        display: flex;
        gap:20px;
        aside{
            max-width: 245px;
            width: 100%;
            position: relative;
        }
        .personal-info__aside{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 60px;
            .main-personal-info-container{
                display: flex;
                flex-direction: column;
                gap:10px;
            }
            .social-icons-container{
                display: flex;
                flex-direction: column;
                gap:15px;
                svg{
                    max-width: 40px;
                    &:hover{
                        transition: 0.3s ease;
                        fill: $accent;
                    }
                }
            }
            .social-media-links-container{
                display: flex;
                flex-direction: column;
                gap:10px;
                .selected-link{
                    padding: 10px;
                    border: 2px solid white;
                }
            }
        }
        .profile-photo-container{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            position: relative;
            padding: 30px 0px;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit:contain;
            }
        }
        .career__aside{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap:10px;
            align-items: flex-end;
            h1{
                text-align: center;
            }
            .proceed-bar{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $white;
                    background: $white;
                    color: $black;
                    transition: 1s ease;
                    &:hover{
                        background-color: $accent;
                        color: $white;
                        transition: 0.4s ease !important;
                        border:1px solid $accent
                    }
                }
                .line{
                    width: 5px;
                    height: 200px;
                    background-color: $white;
                    transition: 1s ease;
                }
                button,.line{
                    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.05);
                }
                .completed{
                    background: red;
                    color:$white;
                }
            }
        }
    }
}

.personal-info-small__section{
    background: $black;
    position: relative;
    color: white;
    display: none;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    gap:20px;
    .background-image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }
    .personal-info-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap:20px;
        padding-bottom: 20px;
    }
    .social-icons-container{
        display: flex;
        gap:15px;
        svg{
            width: 40px;
            &:hover{
                transition: 0.3s ease;
                fill: $accent;
            }
        }
    }
    .social-media-links-container{
        display: flex;
        flex-direction: column;
        gap:10px;
        .selected-link{
            padding: 10px;
            border: 2px solid white;
        }
    }
    .profile-photo-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        position: relative;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit:contain;
        }
    }
}

.projects__section{
    background-color: $black;
    padding: 50px;
    display: flex;
    flex-direction: column;
    h1{
        margin: 50px 0px;
    }
    .project-card-grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 40px;
        .project-card{
            border:1px solid $accent;
            border-radius: 25px;
            cursor: pointer;
            transition: 0.3s ease;
            img{
                width: 100%;
                object-fit: cover;
                border-radius: 25px 25px;
                max-height: 425px;
            }
            .description__container{
                display: flex;
                flex-direction: column;
                gap:45px;
                padding: 20px;
            }
            &:hover{
                background-color: $accent2;
            }
        }
    }
}

.big-screen{
    display: flex;
}

@media screen and (max-height:790px) {
    .personal-info__section{
        .personal-info__aside{
            .social-icons-container{
                gap:15px !important;
                flex-direction: row !important;
            }
        }
    }
}

@media screen and (max-width:785px){
    .personal-info__section{
        .social-icons-container{
            flex-direction: row !important;
        }
    }
    .personal-info-small__section{
        display: flex !important;
    }
    .big-screen{
        display: none !important;
    }
}

@media screen and (max-width:1900px) {
    .projects__section{
        .project-card-grid{
            grid-template-columns: 1fr 1fr 1fr !important;
        }
    }
}

@media screen and (max-width:1145px) {
    .personal-info__section{
        .career__aside{
            display: none !important;
        }
    }
}